function getScrollTop(scrollElement) {
  const scrollNode = scrollElement || document.scrollingElement;
  return scrollNode.scrollTop + scrollNode.offsetTop;
}

export function getElementTop(element) {
  let top = 0;
  while (element && element.tagName.toLowerCase() !== 'body') {
    top += element.offsetTop;
    element = element.offsetParent;
  }
  return top;
}

function getElementBottom(element, elementTop) {
  const { offsetHeight } = element;
  return elementTop + offsetHeight;
}

function getViewPortHeight(scrollElement) {
  if (scrollElement) {
    return scrollElement.offsetHeight;
  }

  const { documentElement } = document;
  const { innerHeight } = window;
  if (innerHeight) {
    return innerHeight;
  } else if (!documentElement) {
    return 0;
  }

  const { clientHeight } = documentElement;
  if (Number.isNaN(clientHeight)) {
    return 0;
  }

  return clientHeight;
}

export default function isElementOnScreen(element, scrollElement) {
  if (!element) {
    return false;
  }

  if (__DEV__) {
    if (scrollElement) {
      const position =
        scrollElement.style.position ||
        window.getComputedStyle(scrollElement).getPropertyValue('position');
      if (!['relative', 'absolute'].includes(position)) {
        throw new Error(
          `scrollElement must have a position of relative or absolute in order for this element's offset to be calculated properly.`
        );
      }
    }
  }

  const scrollTop = getScrollTop(scrollElement);
  const elementTop = getElementTop(element);
  const elementBottom = getElementBottom(element, elementTop);
  const viewPortHeight = getViewPortHeight(scrollElement);

  const scrollBottom = scrollTop + viewPortHeight;

  if (elementTop < scrollBottom && elementTop > scrollTop) {
    return true;
  } else if (elementBottom < scrollBottom && elementBottom > scrollTop) {
    return true;
  } else if (elementTop < scrollTop && elementBottom > scrollBottom) {
    return true;
  } else {
    return false;
  }
}

export function isElementCompletelyOnScreen(element, scrollElement) {
  if (!element) {
    return false;
  }

  if (__DEV__) {
    if (scrollElement) {
      const position =
        scrollElement.style.position ||
        window.getComputedStyle(scrollElement).getPropertyValue('position');
      if (!['relative', 'absolute'].includes(position)) {
        throw new Error(
          `scrollElement must have a position of relative or absolute in order for this element's offset to be calculated properly.`
        );
      }
    }
  }

  const scrollTop = getScrollTop(scrollElement);
  const elementTop = getElementTop(element);
  const elementBottom = getElementBottom(element, elementTop);
  const viewPortHeight = getViewPortHeight(scrollElement);
  const scrollBottom = scrollTop + viewPortHeight;

  const isVisible = elementTop >= scrollTop && elementBottom <= scrollBottom;
  let below = false;
  let above = false;

  if (!isVisible) {
    if (elementTop < scrollTop) {
      above = true;
    } else {
      below = true;
    }
  }

  return { isVisible, above, below };
}
