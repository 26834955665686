import isPlainObject from 'common/isPlainObject';
import clone from 'common/util/clone';

const DefaultConfig = {
  boardToken: null,
  ssoToken: null,

  basePath: null,
  display: null,
  initialPath: '/',
  initialQuery: {},
  origin: null,
};

export default function SDKConfig(config) {
  this._config = clone(DefaultConfig);

  for (var key in config) {
    var value = config[key];
    if (!isPlainObject(value)) {
      this._config[key] = value;
      continue;
    }

    for (var childKey in value) {
      var childValue = value[childKey];
      this._config[key][childKey] = childValue;
    }
  }
}

SDKConfig.prototype.get = function () {
  return clone(this._config);
};
