import devURL from 'common/util/devURL';

const SDKAJAX = {
  _send: function (url, callback, method, data) {
    var request = new XMLHttpRequest();
    const domain = devURL('https://api.canny.io');
    url = domain + url;
    request.open(method, url, true);
    request.onload = function () {
      if (request.status === 200 || request.status === 304) {
        callback && callback(request.responseText);
      } else {
        callback && callback('server error');
      }
    };
    request.onerror = function (e) {
      e.preventDefault();
      callback && callback('server error');
    };
    if (method === 'POST') {
      request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    }
    request.withCredentials = true;
    request.send(data);
  },

  get: function (url, data, callback) {
    var query = [];
    for (var key in data) {
      query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
    }
    SDKAJAX._send(`${url}?${query.join('&')}`, callback, 'GET');
  },

  post: function (url, data, callback) {
    var query = [];
    for (var key in data) {
      query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
    }
    SDKAJAX._send(url, callback, 'POST', query.join('&'));
  },
};

export default SDKAJAX;
