const sessionStorageKey = 'sessionStorage';

// When third party cookies are blocked in Chrome, accessing sessionStorage will
// throw an error. React Router v3 uses History v3 which has an unpatched bug
// where sessionStorage is accessed without a try/catch.
//
// Someone did try patching it here: https://github.com/ReactTraining/history/pull/439
// However, the maintainers never released the update to npm.
//
// This patch works in combination with a webpack config change. Using webpack.DefinePlugin,
// we replace all `window.sessionStorage` references with `window.sessionStorageWrapper`.
// This gives us the opportunity to intercept and catch the storage access error.

try {
  if (window[sessionStorageKey]) {
    window.sessionStorageWrapper = window[sessionStorageKey];
  } else {
    // For some reason sessionStorage doesn't exist.
    setFallbackStorage();
  }
} catch (err) {
  // We must not be allowed to access sessionStorage.
  setFallbackStorage();
}

// Prevent errors if third party cookies are blocked in Chrome
function setFallbackStorage() {
  window.sessionStorageWrapper = {
    clear() {},
    getItem() {},
    remoteItem() {},
    setItem() {},
  };
}
