export function encode(rawStr: string) {
  // convert non-latin characters to a format that can be used with btoa
  const safeRawStr = encodeURIComponent(rawStr).replace(/%([0-9A-F]{2})/g, function (_, p1) {
    return String.fromCharCode(Number('0x' + p1));
  });

  // btoa allowed in this context because we're safely handling non-latin chars
  // eslint-disable-next-line no-restricted-globals
  return btoa(safeRawStr);
}

export function decode(encodedStr: string) {
  // eslint-disable-next-line no-restricted-globals
  const percentEncodedChars = atob(encodedStr).split('');
  const safeRawStr = percentEncodedChars
    .map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    })
    .join('');

  return decodeURIComponent(safeRawStr);
}
