import 'common/Polyfills';
import 'common/storage/sessionStorageWrapper';

import sha256 from 'crypto-js/sha256';
import { iframeResizer } from 'iframe-resizer';

import { encodeClientToken } from 'common/auth/clientToken';
import Message from 'common/message/Message';
import LocalStorage from 'common/storage/LocalStorage';
import addQueryParamToHref from 'common/util/addQueryParamToHref';
import devURL from 'common/util/devURL';
import hash from 'common/util/hash';
import isElementOnScreen, { getElementTop } from 'common/util/isElementOnScreen';
import queryString from 'common/util/queryString';
import roundCents from 'common/util/roundCents';
import Themes from 'common/util/theme';

import SDKAJAX from './SDKAJAX';
import SDKConfig from './SDKConfig';
import SDKValidation from './SDKValidation';

const CannyWidgetName = 'Canny widget';
const ChangelogBorderRadius = '8px';
const ChangelogDarkBackground = '#1c1c1f';
const ChangelogLightBackground = 'white';
const ChangelogReadID = 'canny-changelog-read';
const ChangelogSeenID = 'canny-changelog-seen';
const ChangelogWidgetIFrameID = 'canny-changelog-iframe';
const ChangelogWidgetMargin = 10;
const ChangelogWidgetName = 'changelog widget';
const ChangelogWidgetOrigin = devURL('https://changelog-widget.canny.io');
const ChangelogWidgetStyleID = 'canny-changeloge-style';
const ChangelogWidgetWidth = 350;
const HeightAnimationDuration = 250;
const MaxCacheAge = 3600000; // 1 hour
const ScrollElementID = 'canny-scroll-element';
const StorageIdentifyKey = 'canny-identify-hash';
const WidgetOrigin = devURL('https://widget.canny.io');
const WidgetSSOTokenStorageName = 'canny-sso-token';

Message.subscribe(null, WidgetOrigin, 'path', function (cannyPath) {
  if (!CannySDK._config.get().basePath) {
    return;
  }

  let basePath = CannySDK._config.get().basePath;
  if (basePath[basePath.length - 1] === '/') {
    basePath = basePath.substr(0, basePath.length - 1);
  }

  const path = basePath + cannyPath;
  window.history.replaceState(window.history.state, null, path);
  return;
});

Message.subscribe(null, WidgetOrigin, 'redirect', function (redirectURL) {
  window.location.assign(redirectURL);
});

Message.subscribe(null, WidgetOrigin, 'refresh', function () {
  window.location.reload();
});

const CannySDK = {
  _appID: null,
  _cache: {},
  _clientToken: null,
  _config: null,
  _domainHashes: {},
  _entryIDs: [],
  _isClickListenerSet: false,
  _changelogOpenCallback: null,

  authenticateCannyLink(link) {
    return addQueryParamToHref(link, 'clientToken', CannySDK._clientToken, false);
  },

  hasUnseenEntries() {
    const seenIDsString = LocalStorage.get(ChangelogSeenID);
    const seenIDs = seenIDsString ? seenIDsString.split(',') : [];
    const hasUnseenEntries = CannySDK._entryIDs.some((entryID) => {
      return !seenIDs.includes(entryID);
    });
    return hasUnseenEntries;
  },

  registerOnChangelogOpenCallback(callback) {
    CannySDK._changelogOpenCallback = callback;
  },

  /**
   * Closing the changelog widget:
   *
   * Initiating close:
   * - [parent] User clicks on button that opened the widget
   * - [parent] User clicks anywhere in body outside the widget
   * - [widget] User clicks on the close widget X in fullscreen mode
   * - [parent] Customer manually calls Canny('closeChangelog')
   *
   * Close process:
   * 1. Parent immediately hides the widget using visibility: hidden
   * 2. Widget runs route.replace(/) to route back to home view
   * 3. Parent sets display: none and visibility: visible
   *
   * Messages:
   * - close-changelog: widget telling parent it would like to close (asking to kickoff #1)
   * - changelog-hidden: parent confirming #1 is done (asking widget to do #2)
   * - close-changelog-cleanup: widget confirming #2 is done (asking parent to do #3)
   *
   * Notes:
   * - #2 will not work in display: none because DOM updates don't get propagated with display: none
   * - We need to do all these in order, otherwise there will be a flicker when closing/reopening the widget
   */
  closeChangelog() {
    const iframe = document.getElementById(ChangelogWidgetIFrameID);
    if (!iframe) {
      return;
    }

    iframe.buttonElement = null;
    iframe.style.visibility = 'hidden';

    setTimeout(() => {
      Message.postMessage(iframe.contentWindow, ChangelogWidgetOrigin, 'changelog-hidden');
    }, 0);
  },

  initChangelog(config, buttonElement) {
    // 1. Validate input
    if (!SDKValidation.appID(config.appID)) {
      console.warn(
        'Canny: Failed to initialize changelog widget because appID is missing or invalid.'
      );
      return;
    } else if (!SDKValidation.changelog.align(config.align)) {
      console.warn(
        'Canny: Failed to initialize changelog widget because align is missing or invalid (must be "top", "bottom", "left", or "right").'
      );
      return;
    } else if (!SDKValidation.changelog.position(config.position)) {
      console.warn(
        'Canny: Failed to initialize changelog widget because position is missing or invalid (must be "top", "bottom", "left", or "right").'
      );
      return;
    } else if (
      (['top', 'bottom'].includes(config.align) && ['top', 'bottom'].includes(config.position)) ||
      (['left', 'right'].includes(config.align) && ['left', 'right'].includes(config.position))
    ) {
      console.warn(
        'Canny: Failed to initialize changelog widget because position and align cannot both be [top, bottom] or [left, right].'
      );
      return;
    }

    // 1.2 Validate SSO Token
    const ssoToken = config.ssoToken || LocalStorage.get(WidgetSSOTokenStorageName);
    const hasSSOToken = ssoToken && typeof ssoToken === 'string';
    const isValidSSOToken = validateSSOToken(ssoToken, ChangelogWidgetName);
    if (hasSSOToken && !isValidSSOToken) {
      return;
    }

    // 2. Make sure buttonElements are valid
    const buttonElements = buttonElement
      ? [buttonElement]
      : Array.from(document.querySelectorAll('[data-canny-changelog]'));
    const areButtonsValid = buttonElements.reduce((valid, buttonElement) => {
      if (!valid || !buttonElement || !(buttonElement instanceof Element)) {
        return false;
      }
      return true;
    }, true);
    if (!areButtonsValid) {
      console.warn(
        'Canny: Failed to initialize Canny changelog widget because no valid buttonElement was passed in or found with data-canny-changelog attribute.'
      );
      return;
    }

    // get changelog IDs on client to determine if unread banner should show
    SDKAJAX.post(
      '/api/changelog/getNewEntryIDs',
      {
        companyID: config.appID,
        ...(config.omitNonEssentialCookies
          ? { omitNonEssentialCookies: config.omitNonEssentialCookies }
          : {}),
        ...(config.labelIDs ? { labelIDs: JSON.stringify(config.labelIDs) } : {}),
      },
      (response) => {
        if (response === 'server error') {
          console.warn('Canny: Something went wrong fetching changelog entries: ', response);
          return;
        }

        try {
          const result = JSON.parse(response).result;
          CannySDK._entryIDs = result.entryIDs ?? [];
        } catch (e) {
          console.warn('Canny: Something went wrong fetching changelog entries: ', response);
          return;
        }

        const seenIDsString = LocalStorage.get(ChangelogSeenID);
        const seenIDs = seenIDsString ? seenIDsString.split(',') : [];
        const hasUnseenEntries = CannySDK._entryIDs.some((entryID) => {
          return !seenIDs.includes(entryID);
        });

        buttonElements.forEach((buttonElement) => {
          if (!hasUnseenEntries) {
            return;
          }

          addUnseenBadge(buttonElement);
        });
      }
    );

    const createIframe = () => {
      // Make sure iframe doesn't already exist
      const existingIframe = document.getElementById(ChangelogWidgetIFrameID);
      if (existingIframe) {
        existingIframe.config = config;
        positionChangelogWidget(existingIframe, existingIframe.buttonElement, config);

        if (existingIframe.loaded) {
          Message.postMessage(
            existingIframe.contentWindow,
            ChangelogWidgetOrigin,
            'calculateHeight',
            {}
          );
          Message.postMessage(
            existingIframe.contentWindow,
            ChangelogWidgetOrigin,
            'changelog-opened',
            {}
          );
        }

        return;
      }

      // Create the iframe
      const iframe = document.createElement('iframe');
      iframe.width = ChangelogWidgetWidth;
      iframe.height = 0;
      iframe.config = config;
      iframe.id = ChangelogWidgetIFrameID;
      iframe.setAttribute(
        'allow',
        'accelerometer; autoplay; encrypted-media; fullscreen; gyroscope; picture-in-picture'
      );
      iframe.style.animation = 'canny-widget-slide-up 0.2s ease';
      iframe.style.background = getIFrameBackground(config.theme);
      iframe.style.border = '0';
      iframe.style.borderRadius = ChangelogBorderRadius;
      iframe.style.boxShadow = '0px 2px 10px rgba(0, 0, 0, 0.15)';
      iframe.style.display = 'none';
      iframe.style.overflow = 'hidden';
      iframe.style.position = 'absolute';
      iframe.style.width = ChangelogWidgetWidth + 'px';
      iframe.style.zIndex = '999999999';

      const cancelAnimation = (iframe) => {
        if (iframe.heightAnimationID) {
          window.cancelAnimationFrame(iframe.heightAnimationID);
          iframe.heightAnimationID = null;
        }
      };

      // See ChangelogWidgetContainer for details about how widget height works
      Message.subscribe(null, ChangelogWidgetOrigin, 'changelog-height', (data) => {
        if (isMobile()) {
          iframe.height = window.innerHeight;
          iframe.style.height = window.innerHeight + 'px';
          cancelAnimation(iframe);
        } else {
          if (!data.resize && Number(iframe.height) !== 0) {
            animateHeight(iframe, data.height);
          } else {
            iframe.height = data.height;
            iframe.style.height = data.height + 'px';
            cancelAnimation(iframe);
          }
        }
        positionChangelogWidget(iframe, iframe.buttonElement, config);
      });

      Message.subscribe(null, ChangelogWidgetOrigin, 'changelog-loaded', (data) => {
        const { entryIDs } = data;

        iframe.entryIDs = entryIDs;
        iframe.loaded = true;

        const readIDsString = LocalStorage.get(ChangelogReadID);
        const readIDs = readIDsString ? readIDsString.split(',') : [];
        Message.postMessage(iframe.contentWindow, ChangelogWidgetOrigin, 'entry-readIDs', {
          readIDs,
        });

        iframe.fullscreenMode = isMobile();
        Message.postMessage(iframe.contentWindow, ChangelogWidgetOrigin, 'set-fullscreen-mode', {
          fullscreenMode: isMobile(),
        });
      });

      Message.subscribe(null, ChangelogWidgetOrigin, 'entry-opened', (data) => {
        const { entryID } = data;
        const readIDsString = LocalStorage.get(ChangelogReadID);
        const readIDs = readIDsString ? readIDsString.split(',') : [];
        if (readIDs.includes(entryID)) {
          return;
        }

        LocalStorage.set(ChangelogReadID, readIDs.concat(entryID).join(','));
      });

      Message.subscribe(
        null,
        ChangelogWidgetOrigin,
        'close-changelog',
        CannySDK.closeChangelog,
        true
      );
      Message.subscribe(null, ChangelogWidgetOrigin, 'close-changelog-cleanup', () => {
        iframe.style.display = 'none';
        iframe.style.visibility = 'visible';
      });
      validateTheme(config);

      // Add iframe to DOM
      const changelogQueryParams = queryString.stringify({
        ...(ssoToken ? { ssoToken } : {}),
        ...(config.labelIDs ? { labelIDs: JSON.stringify(config.labelIDs) } : {}),
        ...(config.omitNonEssentialCookies
          ? { omitNonEssentialCookies: config.omitNonEssentialCookies }
          : {}),
        theme: config.theme,
      });
      iframe.src = `${ChangelogWidgetOrigin}/${config.appID}${changelogQueryParams}`;

      document.body.appendChild(iframe);

      // Listen for window resizes
      window.addEventListener(
        'resize',
        () => {
          const iframe = document.getElementById('canny-changelog-iframe');
          if (!iframe) {
            console.warn('Canny: Our iframe was removed, unable to open changelog widget.');
            return;
          }

          positionChangelogWidget(iframe, iframe.buttonElement, iframe.config, { resize: true });
        },
        false
      );

      // Listen for clicks
      document.body.addEventListener(
        'click',
        (e) => {
          const isHidden = iframe.style.display === 'none' && iframe.style.visibility === 'visible';
          if (clickWasOnButtonElement(e.target) || iframe.contains(e.target) || isHidden) {
            return;
          }

          CannySDK.closeChangelog();
        },
        true
      );
    };
    // 3. Listen for button clicks
    buttonElements.forEach((buttonElement) => {
      if (buttonElement.hasCannyClickListener) {
        console.warn(
          'Canny: You can only initialize the widget once, re-initializations may result in errors or unpredictable behaviour.'
        );
        return;
      }

      buttonElement.hasCannyClickListener = true;
      buttonElement.dataset.cannyChangelog = true;
      buttonElement.addEventListener('mouseover', function (buttonElement) {
        createIframe();
      });
      buttonElement.addEventListener(
        'click',
        function (buttonElement) {
          createIframe();

          const iframe = document.getElementById(ChangelogWidgetIFrameID);
          if (!iframe) {
            console.warn('Canny: Our iframe was removed, unable to open/close changelog widget.');
            return;
          }

          const iframeOpen = iframe.style.display === 'block';
          if (iframeOpen) {
            if (iframe.buttonElement && iframe.buttonElement !== buttonElement) {
              iframe.buttonElement = buttonElement;
              positionChangelogWidget(iframe, buttonElement, iframe.config);
            } else {
              CannySDK.closeChangelog();
            }
            return;
          }

          // render 1 frame invisible to avoid flickers on reopen
          iframe.style.visibility = 'hidden';
          iframe.style.display = 'block';
          setTimeout(() => {
            iframe.style.visibility = 'visible';
          }, 10);
          iframe.buttonElement = buttonElement;
          iframe.hasUnseenEntries = false;
          positionChangelogWidget(iframe, buttonElement, iframe.config, { resize: true });

          const unseenBadges = Array.from(document.querySelectorAll('.Canny_BadgeContainer'));
          unseenBadges.forEach((unseenBadge) => {
            if (unseenBadge && unseenBadge.parentElement) {
              unseenBadge.parentElement.removeChild(unseenBadge);
            }
          });

          if (CannySDK._changelogOpenCallback) {
            CannySDK._changelogOpenCallback();
          }

          const seenIDsString = LocalStorage.get(ChangelogSeenID);
          const seenIDs = seenIDsString ? seenIDsString.split(',') : [];
          const newSeenIDs = CannySDK._entryIDs.reduce((seenIDs, entryID) => {
            return seenIDs.includes(entryID) ? seenIDs : seenIDs.concat(entryID);
          }, seenIDs);
          LocalStorage.set(ChangelogSeenID, newSeenIDs);
        }.bind(null, buttonElement),
        false
      );

      const existingIframe = document.getElementById(ChangelogWidgetIFrameID);
      if (!existingIframe || !existingIframe.loaded || !existingIframe.hasUnseenEntries) {
        return;
      }

      addUnseenBadge(buttonElement);
    });

    // 4. Add stylesheet
    const existingStylesheet = document.getElementById(ChangelogWidgetStyleID);
    if (existingStylesheet) {
      return;
    }
    const stylesheet = document.createElement('style');
    stylesheet.id = ChangelogWidgetStyleID;
    stylesheet.setAttribute('type', 'text/css');
    stylesheet.innerHTML =
      '.Canny_BadgeContainer { position: absolute; top: 0; bottom: 0; left: 0; right: 0; visibility: hidden } .Canny_Badge { position: absolute; top: -1px; right: -1px; border-radius: 10px; background-color: red; padding: 5px; border: 1px solid white; visibility: visible } @keyframes canny-widget-slide-up { from { opacity:0.6; transform: translateY(8px); } to { opacity: 1; transform: translateY(0); } }';
    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(stylesheet);
  },

  async identify(data, callback) {
    if (typeof callback !== 'function') {
      callback = function () {};
    }

    const success = validateIdentify(data, callback);
    if (!success) {
      callback();
      return;
    }

    // fix spend values
    (data.user.companies || []).forEach((company) => {
      if (company.hasOwnProperty('monthlySpend')) {
        company.monthlySpend = roundCents(company.monthlySpend);
        if (Number.isNaN(company.monthlySpend)) {
          delete company.monthlySpend;
        }
      }
    });

    CannySDK._appID = data.appID;
    if (!CannySDK._domainHashes[data.appID]) {
      await new Promise((resolve, reject) => {
        const appID = data.appID;
        SDKAJAX.post(`/api/company/getDomainHashes/${appID}`, {}, (responseJSON) => {
          try {
            const response = JSON.parse(responseJSON);
            const domainHashes = response.domainHashes;
            CannySDK._domainHashes[appID] = domainHashes;
          } catch (error) {
            // no-op
          } finally {
            resolve();
          }
        });
      });
    }

    // compute clientToken for data
    const clientToken = encodeClientToken(data);
    CannySDK._clientToken = clientToken;

    // set click listener if not already set
    if (CannySDK._isClickListenerSet) {
      callback();
      return;
    }

    CannySDK._isClickListenerSet = true;

    const eventHandler = (e) => {
      const cannyLink = getCannyLink(e.target);

      // only handle events relating to Canny links
      if (!cannyLink) {
        return;
      }

      // update href to include the clientToken for authentication
      cannyLink.href = addQueryParamToHref(
        cannyLink.href,
        'clientToken',
        CannySDK._clientToken,
        false
      );
    };

    // intercept all cases where a link is clicked on
    // click: link was clicked on
    document.body.addEventListener('click', eventHandler, true);
    // contextmenu: link was right clicked on (eg. open link in new tab)
    document.body.addEventListener('contextmenu', eventHandler, true);
    // focusin: link was tabbed to (user might press enter to open link)
    document.body.addEventListener('focusin', eventHandler, true);
    // mousedown: handles middle mouse button case
    document.body.addEventListener('mousedown', eventHandler, true);

    await createOrUpdateUser(data);

    callback();
  },

  render(config, containerElement) {
    // Check containerElement is legit
    if (!containerElement) {
      containerElement = document.querySelectorAll('[data-canny]')[0];
      if (!containerElement) {
        console.warn(
          'Canny: Failed to render Canny widget because no containerElement was passed in or found with data-canny attribute.'
        );
        return;
      }
    }

    // Check iframe does not already exist
    const existingIframe = document.getElementById('canny-iframe');
    if (existingIframe) {
      console.warn(
        'Canny: Failed to render Canny widget because there is already an iframe with id canny-iframe.'
      );
      return;
    }

    // Check boardToken was passed in
    if (!config.boardToken) {
      console.warn(
        'Canny: Failed to render Canny widget because no boardToken was set in config. You can find your board token here: https://developers.canny.io/install/widget/web.'
      );
      return;
    } else if (!SDKValidation.boardToken(config.boardToken)) {
      console.warn(
        'Canny: Failed to render Canny widget because the boardToken supplied was invalid. You can find your board token here: https://developers.canny.io/install/widget/web.'
      );
      return;
    }

    // Validate SSO Token
    const ssoToken = config.ssoToken || LocalStorage.get(WidgetSSOTokenStorageName);
    const hasSSOToken = ssoToken && typeof ssoToken === 'string';
    const isValidSSOToken = validateSSOToken(ssoToken, CannyWidgetName);
    if (hasSSOToken && !isValidSSOToken) {
      return;
    }

    // Create iframe
    const iframe = document.createElement('iframe');
    iframe.width = '100%';
    iframe.height = '800px';
    iframe.id = 'canny-iframe';
    iframe.scrolling = 'no';
    iframe.style.border = '0';

    // If URL syncing is configured, calculate cannyPath
    let cannyPath = '/';
    let cannyQueryParams = {
      boardToken: config.boardToken,
      ...(config.display && { display: config.display }),
      ...(ssoToken && {
        ssoToken,
      }),
    };

    if (config.basePath) {
      const hashRouting = config.basePath.indexOf('#') === -1 ? false : true;
      const pathname = hashRouting
        ? window.location.pathname + window.location.hash.split('?')[0]
        : window.location.pathname;
      const basePath = config.basePath.endsWith('/')
        ? config.basePath.slice(0, -1)
        : config.basePath;
      const origin = window.location.origin;

      if (pathname.indexOf(basePath) !== 0) {
        console.warn(
          'Canny: Failed to set up URL syncing because basePath (' +
            basePath +
            ') not found in pathname (' +
            pathname +
            ').'
        );
        delete config.origin;
      } else {
        cannyPath = pathname.substr(basePath.length, pathname.length - basePath.length);
        const search = hashRouting
          ? '?' + (window.location.hash.split('?')[1] || '')
          : window.location.search;
        cannyQueryParams = Object.assign(queryString.parse(search), cannyQueryParams);
        config.initialPath = cannyPath;
        config.initialQuery = queryString.parse(search);
        config.origin = origin;
      }
    }

    // Set the iframe's URL and add it to the dom
    iframe.src = WidgetOrigin + cannyPath + queryString.stringify(cannyQueryParams);
    containerElement.appendChild(iframe);
    validateTheme(config);
    // Generate and store a complete config (with default values set)
    CannySDK._config = new SDKConfig(config);

    // React is ready, pass config to widget
    iframe._unsubscribeReady = Message.subscribe(null, WidgetOrigin, 'ready', function () {
      if (!iframe.contentWindow) {
        // Unsubscribe if iframe is unloaded
        iframe._unsubscribeReady();

        // Cleanup scroll listener
        window.removeEventListener('scroll', scrollListener);
        return;
      }

      Message.postMessage(iframe.contentWindow, WidgetOrigin, 'config', CannySDK._config.get());
    });

    // Subscribe to auth tokens
    iframe._unsubscribeToken = Message.subscribe(
      null,
      WidgetOrigin,
      'set-auth-token',
      (ssoToken) => {
        // Unsubscribe if iframe is unloaded
        if (!iframe.contentWindow) {
          iframe._unsubscribeToken();
        }
        LocalStorage.set(WidgetSSOTokenStorageName, ssoToken);
        Message.postMessage(iframe.contentWindow, WidgetOrigin, 'token-set');
      }
    );

    // React content is loaded
    iframe._unsubscribeLoaded = Message.subscribe(null, WidgetOrigin, 'loaded', function () {
      if (!iframe.contentWindow) {
        // Unsubscribe if iframe is unloaded
        iframe._unsubscribeLoaded();
      }
      if (typeof config.onLoadCallback === 'function') {
        config.onLoadCallback();
      }
    });

    // Hook up iframe resizing library
    iframeResizer(
      {
        checkOrigin: [WidgetOrigin],
        heightCalculationMethod: 'taggedElementHeight',
        log: false,
      },
      iframe
    );

    // initialize scroll listening
    const scrollElement = document.createElement('div');
    scrollElement.id = ScrollElementID;
    scrollElement.style.position = 'relative';
    scrollElement.style.top = '-100px';
    scrollElement.style.border = '0';
    scrollElement.style.margin = '0';
    scrollElement.style.padding = '0';
    containerElement.appendChild(scrollElement);

    const scrollListener = function () {
      if (!iframe || !iframe.contentWindow) {
        return;
      }

      const scrollElement = document.getElementById(ScrollElementID);
      if (!scrollElement) {
        return;
      }

      try {
        const elementTop = getElementTop(iframe);
        const { clientHeight, scrollTop } = document.scrollingElement;
        Message.postMessage(iframe.contentWindow, WidgetOrigin, 'scroll', {
          clientHeight,
          scrollTop: scrollTop > elementTop ? scrollTop - elementTop : 0,
        });
      } finally {
        // do nothing
      }

      if (!isElementOnScreen(scrollElement)) {
        return;
      }

      Message.postMessage(iframe.contentWindow, WidgetOrigin, 'scrollBottomDetected', {});
    };

    window.addEventListener('scroll', scrollListener, false);
  },
};

async function createOrUpdateUser(data) {
  // compute unique hash for data
  const dataHash = hash(JSON.stringify(data));

  // check memory for recent similar requests
  const cacheEntry = CannySDK._cache[dataHash];
  if (cacheEntry) {
    const { timestamp } = cacheEntry;
    const now = new Date();
    const cacheAge = now - timestamp;
    const isTimestampRecent = cacheAge > 0 && cacheAge < MaxCacheAge;
    if (isTimestampRecent) {
      console.warn(
        'Canny: Skipping identify request because an identical request was made in the past hour'
      );
      return;
    } else {
      delete CannySDK._cache[dataHash];
    }
  }

  // check local storage for recent similar requests
  const cacheString = LocalStorage.get(StorageIdentifyKey);
  if (cacheString) {
    let cacheData = null;
    try {
      cacheData = JSON.parse(cacheString);
    } catch (e) {
      console.warn(e);
    }

    if (cacheData && cacheData.dataHash && cacheData.timestamp) {
      const now = new Date();
      const timestamp = new Date(cacheData.timestamp);
      const cacheAge = now - timestamp;
      const isTimestampRecent = cacheAge > 0 && cacheAge < MaxCacheAge;
      const hashMatches = cacheData.dataHash === dataHash;
      if (isTimestampRecent && hashMatches) {
        console.warn(
          'Canny: Skipping identify request because an identical request was made in the past hour'
        );
        return;
      }
    }
    LocalStorage.remove(StorageIdentifyKey);
  }

  // set request details in memory and local storage cache
  CannySDK._cache[dataHash] = {
    timestamp: new Date(),
  };
  LocalStorage.set(
    StorageIdentifyKey,
    JSON.stringify({
      dataHash,
      timestamp: new Date(),
    })
  );

  return new Promise((resolve) => {
    SDKAJAX.post(
      '/api/users/identify',
      {
        companyID: data.appID,
        hash: data.hash,
        user: JSON.stringify({
          ...(data.user.avatarURL && {
            avatarURL: data.user.avatarURL,
          }),
          ...(data.user.companies && {
            companies: data.user.companies
              .filter((company) => validateCompany(company))
              .map((company) => ({
                id: String(company.id),
                name: company.name,
                ...(company.created && {
                  created: company.created,
                }),
                ...(company.customFields && {
                  customFields: company.customFields,
                }),

                ...(company.hasOwnProperty('monthlySpend') && {
                  monthlySpend: roundCents(company.monthlySpend),
                }),
              })),
          }),
          ...(data.user.created && {
            created: data.user.created,
          }),
          ...(data.user.customFields && {
            customFields: data.user.customFields,
          }),
          email: data.user.email,
          id: String(data.user.id),
          name: data.user.name,
        }),
      },
      (response) => {
        if (response === 'success') {
          const cacheData = {
            dataHash,
            timestamp: new Date(),
          };
          const cacheString = JSON.stringify(cacheData);
          LocalStorage.set(StorageIdentifyKey, cacheString);
        } else {
          console.warn('Canny: Something went wrong identifying user: ', response);
        }

        resolve();
      }
    );
  });
}

function callCannySDK() {
  const methodName = arguments[0];
  const methodArgs = Array.prototype.slice.call(arguments, 1);

  if (!methodName) {
    console.warn('Canny: No methodName supplied to SDK');
    return;
  }

  const method = CannySDK[methodName];
  if (!method) {
    console.warn('Canny: Invalid methodName supplied to SDK: ' + methodName);
    return;
  }

  return method.apply(null, methodArgs);
}

function addUnseenBadge(buttonElement) {
  const children = Array.from(buttonElement.children);
  const alreadyHasBadge = children.some((child) => {
    return child.className === 'Canny_BadgeContainer';
  });

  if (alreadyHasBadge) {
    return;
  }

  buttonElement.style.position = 'relative';

  const badgeContainer = document.createElement('div');
  badgeContainer.className = 'Canny_BadgeContainer';

  const badge = document.createElement('div');
  badge.className = 'Canny_Badge';

  badgeContainer.appendChild(badge);
  buttonElement.appendChild(badgeContainer);
}

function animateHeight(iframe, finalHeight) {
  if (iframe.heightAnimationID) {
    if (finalHeight === iframe.desiredHeight) {
      return;
    }
    window.cancelAnimationFrame(iframe.heightAnimationID);
  }

  const startTime = new Date();
  const startHeight = Number(iframe.height);
  iframe.desiredHeight = finalHeight;

  const animationStep = () => {
    const now = new Date();
    const time = now - startTime;
    if (time > HeightAnimationDuration) {
      iframe.height = finalHeight;
      iframe.style.height = finalHeight + 'px';
      iframe.heightAnimationID = null;
      iframe.desiredHeight = null;
      positionChangelogWidget(iframe, iframe.buttonElement, iframe.config);
      return;
    }

    const height = Math.round(
      easeInOutQuad(startTime, startHeight, finalHeight, HeightAnimationDuration)
    );
    iframe.height = height;
    iframe.style.height = height + 'px';
    positionChangelogWidget(iframe, iframe.buttonElement, iframe.config);

    iframe.heightAnimationID = window.requestAnimationFrame(animationStep);
  };
  iframe.heightAnimationID = window.requestAnimationFrame(animationStep);
}

// From https://stackoverflow.com/questions/38497765/pure-javascript-animation-easing
function easeInOutQuad(startTime, startValue, endValue, duration) {
  let time = new Date() - startTime;
  const changeInValue = endValue - startValue;
  if ((time /= duration / 2) < 1) {
    return (changeInValue / 2) * time * time + startValue;
  } else {
    return (-changeInValue / 2) * (--time * (time - 2) - 1) + startValue;
  }
}

function clickWasOnButtonElement(target) {
  if (target.dataset.cannyChangelog) {
    return true;
  } else if (!target.parentElement) {
    return false;
  } else {
    return clickWasOnButtonElement(target.parentElement);
  }
}

function getIFrameBackground(theme) {
  if (theme === Themes.light) {
    return ChangelogLightBackground;
  } else if (theme === Themes.dark) {
    return ChangelogDarkBackground;
  } else if (theme === Themes.auto) {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    if (darkThemeMq.matches) {
      return ChangelogDarkBackground;
    } else {
      return ChangelogLightBackground;
    }
  } else {
    // default back to white if theme is unknown
    return ChangelogLightBackground;
  }
}

function getWindowPosition(element) {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top + window.pageYOffset,
    bottom: rect.top + window.pageYOffset + element.offsetHeight,
    left: rect.left + window.pageXOffset,
    right: rect.left + window.pageXOffset + element.offsetWidth,
  };
}

function getCannyLink(element) {
  // if we are currently on canny.io, do not auth links
  if (window.location.host.match(/canny\.io/)) {
    return null;
  }

  // bubble up the DOM tree to find if the element is inside an <a href>
  const maxBubbles = 10;
  for (let i = 0; i < maxBubbles; i++) {
    if (!element) {
      return null;
    }
    if (element.tagName === 'A') {
      break;
    }
    element = element.parentElement;
  }
  if (!element) {
    return null;
  } else if (element.tagName !== 'A' || !element.href || !element.hostname) {
    // if the element is not a link, it is not a Canny link
    return null;
  }

  // element is a link, or inside a link
  const anchor = element;

  // if data-canny-link is set, it is a Canny link
  if (anchor?.dataset?.hasOwnProperty?.('cannyLink')) {
    return anchor;
  }
  // if link is a custom domain, it is a Canny link
  if (CannySDK._appID) {
    const domainHashes = CannySDK._domainHashes[CannySDK._appID];
    if (domainHashes) {
      const hostnameHash = sha256(`${CannySDK._appID}:${anchor.hostname}`).toString();
      if (domainHashes.includes(hostnameHash)) {
        return anchor;
      }
    }
  }
  // if link host does not end with .canny.io, it is not a Canny link
  if (!anchor.hostname?.endsWith?.('.canny.io')) {
    return null;
  }
  // if link has more than three parts (eg. a.b.canny.io), it is not a Canny link
  const parts = anchor.hostname?.split?.('.');
  if (!parts || parts.length !== 3) {
    return null;
  }
  // it is a Canny link (ends with .canny.io)
  return anchor;
}

function isMobile() {
  return window.innerWidth <= 500;
}

function positionChangelogWidget(iframe, buttonElement, config, options = { resize: false }) {
  if (!buttonElement || !config || !iframe.loaded || iframe.style.display === 'none') {
    return;
  }

  const fullscreenMode = isMobile();
  if (iframe.fullscreenMode !== fullscreenMode) {
    iframe.fullscreenMode = fullscreenMode;
    Message.postMessage(iframe.contentWindow, ChangelogWidgetOrigin, 'set-fullscreen-mode', {
      fullscreenMode,
    });
    Message.postMessage(iframe.contentWindow, ChangelogWidgetOrigin, 'calculateHeight', {
      resize: options.resize,
    });
  }

  if (fullscreenMode) {
    iframe.style.position = 'fixed';
    iframe.style.top = 0;
    iframe.style.bottom = 0;
    iframe.style.left = 0;
    iframe.style.right = 0;
    iframe.width = window.innerWidth;
    iframe.height = window.innerHeight;
    iframe.style.width = window.innerWidth + 'px';
    iframe.style.height = window.innerHeight + 'px';
    iframe.style.borderRadius = '0';
    iframe.style.boxShadow = 'none';
    return;
  }

  iframe.style.position = 'absolute';
  iframe.width = 350;
  iframe.style.background = getIFrameBackground(config.theme);
  iframe.style.width = '350px';
  iframe.style.borderRadius = ChangelogBorderRadius;
  iframe.style.boxShadow = '0px 2px 10px rgba(0, 0, 0, 0.15)';

  const buttonPosition = getWindowPosition(buttonElement);
  if (config.align === 'left') {
    iframe.style.left = buttonPosition.left + 'px';
  } else if (config.align === 'right') {
    iframe.style.left = buttonPosition.right - ChangelogWidgetWidth + 'px';
  } else if (config.align === 'top') {
    iframe.style.top = buttonPosition.top + 'px';
  } else if (config.align === 'bottom') {
    iframe.style.top = buttonPosition.bottom - iframe.height + 'px';
  }

  if (config.position === 'left') {
    iframe.style.left = buttonPosition.left - ChangelogWidgetMargin - iframe.width + 'px';
  } else if (config.position === 'top') {
    iframe.style.top = buttonPosition.top - ChangelogWidgetMargin - iframe.height + 'px';
  } else if (config.position === 'bottom') {
    iframe.style.top = buttonPosition.bottom + ChangelogWidgetMargin + 'px';
  } else if (config.position === 'right') {
    iframe.style.left = buttonPosition.right + ChangelogWidgetMargin + 'px';
  }
}

function validateCompany(company) {
  if (!SDKValidation.company.id(company.id)) {
    console.warn('Canny: Invalid company, company.id is missing or invalid:', company.id);
    return false;
  } else if (!SDKValidation.company.name(company.name)) {
    console.warn('Canny: Invalid company, company.name is missing or invalid:', company.name);
    return false;
  } else if (company.created && !SDKValidation.created(company.created)) {
    console.warn('Canny: Invalid company, company.created is invalid:', company.created);
    return false;
  } else if (company.hasOwnProperty('monthlySpend')) {
    if (!SDKValidation.company.monthlySpend(company.monthlySpend)) {
      console.warn(
        'Canny: Invalid company, company.monthlySpend is missing or invalid:',
        company.monthlySpend
      );
      return false;
    }
  }
  return true;
}

function validateIdentify(data) {
  if (!data) {
    console.warn('Canny: Failed to identify because data parameter is missing.');
    return false;
  } else if (!SDKValidation.appID(data.appID)) {
    console.warn('Canny: Failed to identify because appID is missing or invalid.', data.appID);
    return false;
  } else if (!data.user) {
    console.warn('Canny: Failed to identify because user is missing.');
    return false;
  } else if (!SDKValidation.user.email(data.user.email)) {
    console.warn(
      'Canny: Failed to identify because user.email is missing or invalid.',
      data.user.email
    );
    return false;
  } else if (!SDKValidation.user.id(data.user.id)) {
    console.warn('Canny: Failed to identify because user.id is missing or invalid.', data.user.id);
    return false;
  } else if (!SDKValidation.user.name(data.user.name)) {
    console.warn(
      'Canny: Failed to identify because user.name is missing or invalid.',
      data.user.name
    );
    return false;
  } else if (data.user.created && !SDKValidation.created(data.user.created)) {
    console.warn('Canny: Failed to identify because user.created is invalid.', data.user.created);
    return false;
  } else if (data.user.avatarURL && !SDKValidation.user.avatarURL(data.user.avatarURL)) {
    console.warn(
      'Canny: Failed to identify because user.avatarURL is invalid. Expected a string but got: ',
      data.user.avatarURL
    );
    return false;
  } else if (data.user.alias && !SDKValidation.user.alias(data.user.alias)) {
    console.warn(
      'Canny: Failed to identify because user.alias is invalid. Expected a string but got: ',
      data.user.alias
    );
    return false;
  }

  return true;
}

function validateSSOToken(ssoToken, widgetName = CannyWidgetName) {
  if (!ssoToken || typeof ssoToken !== 'string') {
    return true;
  }

  // skip validation if using the old deprecated hex algorithm
  if (ssoToken.match(/^[a-f0-9]+$/i)) {
    return true;
  }

  if (!SDKValidation.ssoToken(ssoToken)) {
    console.warn(
      `Canny: Failed to render ${widgetName} because the provided ssoToken is invalid. See ssoToken documentation here: https://developers.canny.io/install/widget/sso.`
    );
    return false;
  }

  const payloadJSONBase64 = ssoToken.split('.')[1];
  const normalizedBase64 = payloadJSONBase64.replace(/-/g, '+').replace(/_/g, '/');
  const payloadJSON = decodeURIComponent(escape(window.atob(normalizedBase64)));
  let payload;
  try {
    payload = JSON.parse(payloadJSON);
  } catch (e) {
    console.warn(
      `Canny: Failed to render ${widgetName} because ssoToken payload was not valid JSON.`
    );
    return false;
  }

  if (!payload.id) {
    console.warn(
      `Canny: Failed to render ${widgetName} because required 'id' field was missing from ssoToken payload.`
    );
    return false;
  } else if (!payload.email) {
    console.warn(
      `Canny: Failed to render ${widgetName} because required 'email' field was missing from ssoToken payload.`
    );
    return false;
  } else if (!payload.name) {
    console.warn(
      `Canny: Failed to render ${widgetName} because required 'name' field was missing from ssoToken payload.`
    );
    return false;
  }

  if (!SDKValidation.user.id(payload.id)) {
    console.warn(
      `Canny: Failed to render ${widgetName} because 'id' field in ssoToken payload was present, but invalid.`
    );
    return false;
  } else if (!SDKValidation.user.email(payload.email)) {
    console.warn(
      `Canny: Failed to render ${widgetName} because 'email' field in ssoToken payload was present, but invalid.`
    );
    return false;
  } else if (!SDKValidation.user.name(payload.name)) {
    console.warn(
      `Canny: Failed to render ${widgetName} because 'name' field in ssoToken payload was present, but invalid.`
    );
    return false;
  }

  return true;
}

function validateTheme(config) {
  if (!config.theme) {
    return;
  }
  const isValidTheme =
    config.theme === Themes.dark || config.theme === Themes.light || config.theme === Themes.auto;
  if (!isValidTheme) {
    console.warn(
      `Canny: Failed to render Canny widget with theme: ${config.theme}, widget will be rendered with light theme. Please check the theme you provided in your config. We only support 'auto', 'light' and 'dark' theme at the moment.`
    );
    config.theme = Themes.light;
  }
}

if (!window?.Canny?.initialized) {
  const queue = (window.Canny && window.Canny.q) || [];
  queue.forEach((args) => {
    callCannySDK.apply(null, args);
  });

  window.Canny = callCannySDK;
  window.Canny.initialized = true;
}
