import { decode as decodeBase64, encode as encodeBase64 } from 'common/util/base64';

type Payload = {
  appID: string;
  user: {
    email: string;
    id: string;
    name: string;
  };
};

export function encodeClientToken(payload: Payload) {
  const clientToken = encodeURIComponent(encodeBase64(JSON.stringify(payload)));
  return clientToken;
}

export function decodeClientToken(clientToken: string) {
  const urlDecoded = decodeURIComponent(clientToken);
  const base64Decoded = decodeBase64(urlDecoded);
  const payload = JSON.parse(base64Decoded);
  return payload;
}
