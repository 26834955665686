function storageAvailable() {
  try {
    var storage = window.localStorage;
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      storage.length !== 0
    );
  }
}

const LocalStorage = {
  get: function (key) {
    if (!storageAvailable()) {
      return null;
    }

    const value = window.localStorage.getItem(key);
    return value ? value : null;
  },

  remove: function (key) {
    if (!storageAvailable()) {
      return null;
    }

    window.localStorage.removeItem(key);
  },

  set: function (key, value) {
    if (!storageAvailable()) {
      return;
    }

    window.localStorage.setItem(key, value);
  },

  setWithExpiry(key, value, ttl) {
    if (!storageAvailable()) {
      return;
    }

    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    window.localStorage.setItem(key, JSON.stringify(item));
  },

  getWithExpiry(key) {
    if (!storageAvailable()) {
      return null;
    }

    const itemStr = window.localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      window.localStorage.removeItem(key);
      return null;
    }

    return item.value;
  },
};

export default LocalStorage;
