export default function addOrUpdateQueryParam(
  href: string,
  paramKey: string,
  paramValue: string,
  needsEncoding: boolean = true
) {
  // Encode key and value
  const encodedKey = needsEncoding ? encodeURIComponent(paramKey) : paramKey;
  const encodedValue = needsEncoding ? encodeURIComponent(paramValue) : paramValue;

  // Split the URL by # to handle URLs with fragments
  const [url, fragment] = href.split('#');

  // Check if the URL already has query parameters
  const [baseURL, query] = url.split('?');
  const hasQueryString = query && query.length > 1;

  // Parse existing query parameters into an object
  const queryParams = new URLSearchParams(hasQueryString ? query : '');

  // Set or update the parameter value
  queryParams.set(encodedKey, encodedValue);

  // Reconstruct the URL with updated or new parameters
  const newURL = baseURL + '?' + queryParams.toString();

  // Reattach the fragment if it exists
  if (fragment) {
    return `${newURL}#${fragment}`;
  }

  return newURL;
}
