export default function isPlainObject(value) {
  if (typeof value !== 'object') {
    return false;
  }

  if (!value) {
    return false;
  }

  if (Array.isArray(value)) {
    return false;
  }

  return true;
}
