const SDKValidation = {
  appID: (appID) => {
    if (typeof appID !== 'string') {
      return false;
    }

    if (appID.length !== 24) {
      return false;
    }

    var regex = /^[a-fA-F0-9]{24}$/;
    return !!appID.match(regex);
  },

  boardToken: (boardToken) => {
    if (typeof boardToken !== 'string') {
      return false;
    }

    if (boardToken.length !== 36) {
      return false;
    }

    var regex = /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/;
    return !!boardToken.match(regex);
  },

  changelog: {
    align: (align) => {
      return align === 'top' || align === 'right' || align === 'bottom' || align === 'left';
    },
    position: (position) => {
      return (
        position === 'top' || position === 'right' || position === 'bottom' || position === 'left'
      );
    },
  },

  company: {
    id: (id) => {
      if (typeof id !== 'string' && typeof id !== 'number') {
        return false;
      }

      const idString = String(id);
      if (idString.length === 0 || idString.length > 200) {
        return false;
      }

      return true;
    },

    monthlySpend: function (spend) {
      let cents;
      if (typeof spend === 'string') {
        cents = Math.round(Number(spend) * 100);
        if (Number.isNaN(cents) || spend.trim().length === 0) {
          return false;
        }
      } else if (typeof spend === 'number') {
        cents = Math.round(spend * 100);
      } else {
        return false;
      }

      // If it's not an integer, it has too many decimal places
      return Number.isInteger(cents) && cents >= 0;
    },

    name: (name) => {
      if (typeof name !== 'string') {
        return false;
      }

      if (name.length === 0 || name.length > 50) {
        return false;
      }

      return true;
    },
  },

  created: (created) => {
    const date = Date.parse(created);
    return !Number.isNaN(date);
  },

  ssoToken: (ssoToken) => {
    if (typeof ssoToken !== 'string') {
      return;
    }

    return ssoToken.match(/^[a-z0-9-_=]+\.[a-z0-9-_=]+\.[a-z0-9-_.+/=]+$/i);
  },

  user: {
    alias: (alias) => {
      if (typeof alias !== 'string') {
        return false;
      }

      if (alias.length === 0 || alias.length > 50) {
        return false;
      }

      return true;
    },

    avatarURL: (avatarURL) => {
      if (typeof avatarURL !== 'string') {
        return false;
      }

      return true;
    },

    email: (email) => {
      if (typeof email !== 'string') {
        return false;
      }

      if (email.length < 5 || email.length > 200) {
        return false;
      }

      var regex =
        /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.([a-z]+)|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
      return !!email.match(regex);
    },

    id: (id) => {
      if (typeof id !== 'string' && typeof id !== 'number') {
        return false;
      }

      const idString = String(id);
      if (idString.length === 0 || idString.length > 200) {
        return false;
      }

      return true;
    },

    name: (name) => {
      if (typeof name !== 'string') {
        return false;
      }

      if (name.length === 0 || name.length > 50) {
        return false;
      }

      return true;
    },
  },
};

export default SDKValidation;
