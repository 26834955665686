const queryString = {
  parse(query: string): Record<string, string> {
    const queryPairs = query.slice(1).split('&');
    const keys: string[] = [];
    const values: string[] = [];
    queryPairs.forEach((pairString) => {
      if (!pairString) {
        return;
      }

      const pair = pairString.split('=');
      keys.push(pair[0]);
      values.push(pair[1]);
    });
    const queryParams: Record<string, string> = {};
    keys.forEach((key, i) => {
      if (!key) {
        return;
      }

      const value = values[i];
      if (!value) {
        queryParams[decodeURIComponent(key)] = '';
      } else {
        queryParams[decodeURIComponent(key)] = decodeURIComponent(values[i]);
      }
    });
    return queryParams;
  },

  stringify(queryParams: Record<string, string | number | boolean | null>) {
    const queryPairs = [];
    for (const key in queryParams) {
      const value = queryParams[key];
      if (!value && value !== 0 && value !== false) {
        queryPairs.push(encodeURIComponent(key));
      } else {
        queryPairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
      }
    }

    if (queryPairs.length === 0) {
      return '';
    }

    return '?' + queryPairs.join('&');
  },
};

export default queryString;
