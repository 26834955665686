const MessagePrefix = '[canny]';

/**
 * This class handles messages sent between windows via the Window.postMessage API.
 *
 * Messages are of the format [canny]{"type": "messageType": "data": "messageContents"}
 *
 * - Send messages with Message.postMessage.
 * - Subscribe to a specific messageType with Message.subscribe.
 * - Unsubscribe by calling the callback returned by Message.subscribe.
 */

var _subscriptions = {};

function _messageReceived(event) {
  // 1. Check for [canny] prefix, remove it
  let message = event.data;
  if (typeof message !== 'string' || message.indexOf(MessagePrefix) !== 0) {
    return;
  }
  message = message.replace(MessagePrefix, '');

  // 2. JSON parse the rest
  let messageObject;
  try {
    messageObject = JSON.parse(message);
  } catch (e) {
    console.warn('Canny: Invalid message received: ' + message);
    return;
  }

  // 3. Make sure it's the format we expect
  if (!messageObject.hasOwnProperty('type') || !messageObject.hasOwnProperty('data')) {
    console.warn('Canny: Malformed message sent: ' + message);
    return;
  }

  // 4. Call relevant subscribers
  const subscribers = _subscriptions[messageObject.type];
  if (!subscribers) {
    return;
  }

  subscribers.forEach(function (subscriber) {
    if (subscriber.sourceWindow && event.source !== subscriber.sourceWindow) {
      return;
    }
    if (subscriber.origin) {
      if (subscriber.origin instanceof RegExp) {
        if (!subscriber.origin.test(event.origin)) {
          return;
        }
      } else if (typeof subscriber.origin === 'string') {
        if (subscriber.origin !== event.origin) {
          return;
        }
      } else {
        console.warn('Canny: Invalid subscriber origin: ' + JSON.stringify(subscriber.origin));
        return;
      }
    }

    subscriber.callback(messageObject.data, event.origin);
  });
}

if (typeof window !== 'undefined') {
  window.addEventListener('message', _messageReceived, false);
}

const Message = {
  postMessage(recipientWindow, origin, messageType, data = {}) {
    if (!recipientWindow) {
      return;
    }

    const message =
      MessagePrefix +
      JSON.stringify({
        data: data,
        type: messageType,
      });

    recipientWindow.postMessage(message, origin);
  },

  subscribe(sourceWindow, origin, messageType, callback) {
    if (!_subscriptions[messageType]) {
      _subscriptions[messageType] = [];
    }

    _subscriptions[messageType].push({
      callback: callback,
      origin: origin,
      sourceWindow: sourceWindow,
    });

    const unsubscribe = function () {
      setTimeout(() => {
        for (let i = 0; i < _subscriptions[messageType].length; i++) {
          const subscription = _subscriptions[messageType][i];
          if (subscription.callback === callback) {
            _subscriptions[messageType].splice(i, 1);
            return;
          }
        }
      }, 0);
    };
    return unsubscribe;
  },
};

export default Message;
