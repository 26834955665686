import isServer from 'common/util/isServer';

enum Themes {
  dark = 'dark',
  light = 'light',
  auto = 'auto',
}

export const ThemeCookieName = '__canny__browserTheme';

export type Theme = keyof typeof Themes;

export const getThemeFromBrowserSettings = (cookies?: Record<string, string>) => {
  if (isServer()) {
    const cookieTheme = cookies?.[ThemeCookieName];
    if (cookieTheme === Themes.dark || cookieTheme === Themes.light) {
      return cookieTheme;
    }
    return Themes.light;
  }
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    ? Themes.dark
    : Themes.light;
};

export const calculateTheme = ({
  configTheme,
  browserTheme,
}: {
  configTheme?: Theme;
  browserTheme: Theme;
}) => {
  // if theme is auto on purpose, we will render as user setting
  if (configTheme === Themes.auto) {
    return browserTheme;
  }
  if (configTheme === Themes.light || configTheme === Themes.dark) {
    return configTheme;
  }
  // if user gave wrong theme or no theme is set, we will default to light theme
  return Themes.light;
};

export default Themes;
